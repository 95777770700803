import React, { useEffect, useState } from 'react';
import { OfferResponseInterface } from '../api/types/OfferTypes';
import { http } from '../api/http';
import { Error } from '../components/Error';
import { offer } from '../api/queries/offer';
import { ArticleComponent } from '../components/ArticleComponent';
import { OfferForm } from '../components/OfferForm';

interface StateInterface {
  data: OfferResponseInterface | null;
  loading: boolean;
  error: boolean;
}

export function Offer(props: { type: 'mdda' | 'ddaplus' | 'psychoterapia-indywidualna' }) {
  const [state, setState] = useState<StateInterface>({
    data: null,
    error: false,
    loading: true,
  });

  useEffect(() => {
    const controller = new AbortController();

    let id: string = '';

    if (props.type === 'psychoterapia-indywidualna') id = 'cG9zdDoxMzE=';
    if (props.type === 'mdda') id = 'cG9zdDoxMzU=';
    if (props.type === 'ddaplus') id = 'cG9zdDoxMzM=';

    setState((prev) => ({
      ...prev,
      data: null,
      loading: true,
      error: false,
    }));

    http<OfferResponseInterface>(offer(id), { signal: controller.signal })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        if (controller.signal.aborted) return;

        setState((prev) => ({
          ...prev,
          data: null,
          loading: false,
          error: true,
        }));
      });

    return () => controller.abort();
  }, [props.type]);

  if (state.error) {
    return <Error />;
  }

  return (
    <>
      <ArticleComponent
        image={state?.data?.data?.page?.featuredImage?.node?.sourceUrl}
        loading={state.loading}
        title={state.data?.data?.page?.title}
        content={state.data?.data?.page?.content || ''}
      />
      <OfferForm type={props.type} />
    </>
  );
}
