import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { AboutUserInterface } from '../../api/types/AboutTypes';
import { TileDataInterface } from '../Tiles/Tile';
import { Tiles } from '../Tiles';

interface TeamPropsInterface {
  data: AboutUserInterface[];
}

export function Team(props: TeamPropsInterface) {
  const [data, setData] = useState<TileDataInterface[]>([]);

  useEffect(() => {
    const newData: TileDataInterface[] = props.data.map((item) => ({
      text: item.node?.entry?.description || '',
      image: item.node?.entry?.image?.sourceUrl,
      title: item.node?.title || '',
      id: item.node?.id,
      ...(!!item.node?.entry?.position && { tags: [item.node.entry.position] }),
      containImage: true,
    }));

    setData(newData);
  }, [props.data]);

  return (
    <Box
      sx={{
        maxWidth: '1144px',
        width: '100%',
        paddingRight: '16px',
        paddingLeft: '16px',
        marginBottom: '56px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '56px',
          marginBottom: '56px',
        }}
      >
        <Typography variant="h2">Zespół</Typography>
      </Box>
      <Tiles data={data} />
    </Box>
  );
}
