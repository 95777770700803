import React, { useLayoutEffect, useRef } from 'react';
import { Button, Typography, Chip, colors, Box, useTheme } from '@mui/material';
import { useResizeObserver } from '../../helpers/useResizeObserver';
import { UpdateItemHeightAction } from './TilesRow';
import { Link } from 'react-router-dom';
import { formatDate } from '../../helpers/formatDate';

export interface TileDataInterface {
  image?: string;
  title: string;
  text: string;
  id?: string;
  link?: string;
  linkOutside?: string;
  buttonText?: string;
  background?: boolean;
  border?: boolean;
  date?: string;
  tags?: string[];
  containImage?: boolean;
}

interface TilePropsInterface {
  data: TileDataInterface;
  index: number;
  titleContainerHeight: number;
  updateTitleItemHeight: UpdateItemHeightAction;
  textContainerHeight: number;
  updateTextItemHeight: UpdateItemHeightAction;
}

export function Tile(props: TilePropsInterface) {
  const theme = useTheme();

  const titleContainerRef = useRef<HTMLDivElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);

  const imageRef = useResizeObserver<HTMLImageElement>((element) => {
    const { clientWidth } = element;
    const height = (clientWidth / 16) * 9;
    element.style.height = `${height}px`;
  });

  const titleRef = useResizeObserver((element) => {
    const { offsetHeight } = element;
    props.updateTitleItemHeight(offsetHeight, props.index);
  });

  const textRef = useResizeObserver((element) => {
    const { offsetHeight } = element;
    props.updateTextItemHeight(offsetHeight, props.index);
  });

  useLayoutEffect(() => {
    if (!!titleContainerRef.current) titleContainerRef.current.style.height = `${props.titleContainerHeight}px`;
    if (!!textContainerRef.current) textContainerRef.current.style.height = `${props.textContainerHeight}px`;
  }, [props.titleContainerHeight, props.textContainerHeight]);

  const buttonText = props.data?.buttonText || 'CZYTAJ WIĘCEJ';

  const link = !!props.data?.id ? `/artykul/${props.data.id}` : '';

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '3px',
        boxSizing: 'border-box',
        display: 'grid',
        alignItems: 'start',
        justifyItems: 'start',
        gap: '16px',
        backgroundColor: !!props.data?.background ? theme.palette.background.paper : 'none',
        padding: !!props.data?.background || !!props.data?.border ? '8px' : 0,
        border: !!props.data?.border ? `1px solid ${colors.grey['300']}` : undefined,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          position: 'relative',
        }}
      >
        {!!props.data?.date && (
          <Chip
            sx={{
              position: 'absolute',
              top: 4,
              left: 4,
            }}
            variant="filled"
            label={formatDate(props.data.date)}
            color="secondary"
            size="small"
          />
        )}
        {!!props.data?.tags && props.data.tags.length > 0 && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              backgroundColor: 'rgba(0,0,0,0.5)',
              boxSizing: 'border-box',
              padding: '4px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'start',
                margin: '-2px',
                '& > *': {
                  margin: '2px',
                },
              }}
            >
              {props.data.tags.map((item, index) => (
                <Chip key={index} variant="filled" label={item} color="secondary" size="small" />
              ))}
            </Box>
          </Box>
        )}
        <Box
          component="img"
          ref={imageRef}
          sx={{
            width: '100%',
            borderRadius: '3px',
            objectFit: !!props.data?.containImage ? 'contain' : 'cover',
            backgroundColor: !!props.data?.containImage ? '#000000' : 'transparent',
          }}
          alt=""
          src={props.data.image}
        />
      </Box>
      <Box
        ref={titleContainerRef}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          position: 'relative',
        }}
      >
        <Typography ref={titleRef} sx={{ position: 'absolute' }} variant="h5" color="primary">
          {props.data.title}
        </Typography>
      </Box>
      <Box
        ref={textContainerRef}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          position: 'relative',
        }}
      >
        <Typography
          ref={textRef}
          sx={{
            marginTop: '16px',
            marginBottom: '16px',
            position: 'absolute',
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 4,
            WebkitBoxOriental: 'vertical',
          }}
          color="textSecondary"
          variant="body1"
        >
          {props.data.text}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          position: 'relative',
        }}
      >
        {!!props.data?.linkOutside ? (
          <Box
            component="a"
            sx={{ textDecoration: 'none' }}
            rel="noreferrer"
            href={props.data.linkOutside}
            target="_blank"
          >
            <Button variant="contained" color="primary">
              {buttonText}
            </Button>
          </Box>
        ) : (
          <Button component={Link} to={props.data?.link || link} variant="contained" color="primary">
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
}
