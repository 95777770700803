export const whoAreAca = `{
  page(id: "cG9zdDoxNTk=") {
    content
    title
    featuredImage {
      node {
        sourceUrl
      }
    }
  }
}`;
