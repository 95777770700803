import React from 'react';
import { Box, Button, IconButton, Typography, useMediaQuery, styled } from '@mui/material';
import { textData } from '../../media/messages/landing';
import { ReactComponent as TitleImageSvg } from '../../media/images/title-image.svg';
import { ReactComponent as DownloadIcon } from '../../media/icons/download-icon.svg';
import { ReactComponent as FacebookIcon } from '../../media/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../media/icons/instagram.svg';
import PrpLogoCrop from '../../media/images/prp-logo-crop-img.png';
import photo1 from '../../media/images/photo_1.png';
import { Link } from 'react-router-dom';
import { API } from '../../constants';
import Accessible from '@mui/icons-material/Accessible';

const TitleImage = styled(TitleImageSvg)({
  position: 'absolute',
  top: '-20px',
  right: '-64px',
  zIndex: -1,
});

interface FirstViewPropsInterface {
  linkFacebook: string;
  linkInstagram: string;
}

export function FirstView(props: FirstViewPropsInterface) {
  const desktopFontSize = useMediaQuery('(min-width: 600px)');
  const hideTitleImage = useMediaQuery('(min-width: 1120px)');

  return (
    <Box
      sx={{
        maxWidth: '1144px',
        width: '100%',
        position: 'relative',
        marginTop: '48px',
        marginBottom: '160px',
        paddingRight: '16px',
        paddingLeft: '16px',
        '@media screen and (min-width: 1120px)': {
          marginTop: '120px',
          marginBottom: '160px',
        },
      }}
    >
      {hideTitleImage && <TitleImage />}

      <Box
        sx={{
          display: 'grid',
          gap: '64px',
          '@media screen and (min-width: 1120px)': {
            gap: '224px',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gap: '16px',
            '@media screen and (min-width: 1120px)': {
              width: 540,
            },
          }}
        >
          <Typography variant={desktopFontSize ? 'h3' : 'h4'}>{textData.title}</Typography>
          <Typography variant={desktopFontSize ? 'body1' : 'body1'} style={{color: "#909090"}}>Akredytowane przy Polskiej Radzie Psychoterapii</Typography>
          <Typography variant={desktopFontSize ? 'body1' : 'body2'}>{textData.titleDescription}</Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto',
              gap: '16px',
              width: 'fit-content',
              alignItems: 'start',
              '@media screen and (min-width: 400px)': {
                gridTemplateColumns: 'auto auto',
              },
            }}
          >
            <Button variant="contained" color="primary" component={Link} to="/psychoterapia-czym-jest">
              Psychoterapia
            </Button>
            <Button variant="outlined" color="primary" component={Link} to="/kim-sa-dda">
              Kim są DDA?
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: '16px',
            width: 'fit-content',
          }}
        >
          <IconButton href={props.linkFacebook} target="_blank">
            <FacebookIcon />
          </IconButton>
          <IconButton href={props.linkInstagram} target="_blank">
            <InstagramIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'auto',
            gap: '80px',
            '@media screen and (min-width: 1120px)': {
              gridTemplateColumns: 'auto auto',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gap: '16px',
              '@media screen and (min-width: 1120px)': {
                width: 540,
              },
            }}
          >
            <Typography variant={desktopFontSize ? 'h4' : 'h5'}>{textData.titleAbout}</Typography>
            <Typography variant={desktopFontSize ? 'body1' : 'body2'}>{textData.aboutDescription}</Typography>
            <Typography variant={desktopFontSize ? 'body1' : 'body2'} style={{fontWeight: 700}}>Jesteśmy akredytowani przy Polskiej Radzie Psychoterapii</Typography>

            <Box
              component="img"
              sx={{
                width: "10rem"
              }}
              alt="PRP Logo"
              src={PrpLogoCrop}
            />
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto',
                gap: '16px',
                width: 'fit-content',
                alignItems: 'start',
                '@media screen and (min-width: 400px)': {
                  gridTemplateColumns: 'auto auto',
                },
                '@media screen and (min-width: 600px)': {
                  gridTemplateColumns: 'auto auto auto',
                },
              }}
            >
              <Button variant="contained" color="primary" component={Link} to="/kim-jestesmy">
                O nas
              </Button>
              <Button
                target="_blank"
                href={`${API}/wp-content/uploads/2021/06/STATUT-STOWARZYSZENIA-ODDO.pdf`}
                variant="outlined"
                color="primary"
                startIcon={<DownloadIcon />}
              >
                Pobierz statut
              </Button>
              <Button
                target="_blank"
                href={`${API}/wp-content/uploads/2022/05/Tekst-o-dostepnosci-Stowarzyszenia-ostateczna-wersja.pdf`}
                variant="outlined"
                color="primary"
                startIcon={<Accessible />}
              >
                Dostępność
              </Button>
            </Box>
          </Box>
          <Box
            component="img"
            sx={{
              width: '80%',
              justifySelf: 'center',
              objectFit: 'cover',
              '@media screen and (min-width: 1120px)': {
                justifySelf: 'start',
                width: 460,
              },
            }}
            alt=""
            src={photo1}
          />
        </Box>
      </Box>
    </Box>
  );
}
