import React, { useEffect, useState } from 'react';
import { Typography, Button, IconButton, Skeleton, Box, useTheme } from '@mui/material';
import { ReactComponent as FacebookIcon } from '../media/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../media/icons/instagram.svg';
import { ReactComponent as ArrowLeft } from '../media/icons/arrow-left.svg';
import { ReactComponent as UserIcon } from '../media/icons/user-icon.svg';
import { ReactComponent as ClockIcon } from '../media/icons/clock-icon.svg';
import { ReactComponent as CreditCardIcon } from '../media/icons/credit-card-icon.svg';
import { Link } from 'react-router-dom';
import { ContactResponseInterface } from '../api/types/ContactTypes';
import { http } from '../api/http';
import { contact } from '../api/queries/contact';

interface StateInterface {
  data: ContactResponseInterface | null;
  loading: boolean;
  error: boolean;
}

export function Footer() {
  const theme = useTheme();
  const [state, setState] = useState<StateInterface>({
    data: null,
    loading: false,
    error: false,
  });

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: null,
      error: false,
      loading: true,
    }));

    http<ContactResponseInterface>(contact, { signal: controller.signal })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          data: null,
          loading: false,
          error: true,
        }));
      });

    return () => controller.abort();
  }, []);

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#F3F3F3',
        boxSizing: 'border-box',
        marginTop: '32px',
        padding: '16px',
        maxWidth: '1112px',
        width: '100%',
        '@media screen and (min-width: 1080px)': {
          padding: '64px',
          marginBottom: '80px',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto auto',
          alignItems: 'start',
          justifyItems: 'start',
          gap: '32px',
          '@media screen and (min-width: 980px)': {
            gridTemplateRows: 'auto',
            gridTemplateColumns: '1fr 1fr',
            gap: '160px',
          },
        }}
      >
        <div>
          <Box
            sx={{
              marginBottom: '32px',
              '& h5': {
                lineHeight: 0.5,
              },
            }}
          >
            <Typography variant="h5">Masz jakieś pytania?</Typography>
            <br />
            <Typography variant="h5">Skontaktuj się z nami!</Typography>
          </Box>
          <Button endIcon={<ArrowLeft />} variant="outlined" color="primary" component={Link} to="/kontakt">
            Kontakt
          </Button>
        </div>
        <Box
          sx={{
            display: 'grid',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'min-content auto',
              alignItems: 'start',
              justifyItems: 'start',
              gap: '16px',
              '& p': {
                lineHeight: 1.2,
                fontWeight: 'bold',
              },
            }}
          >
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '4px',
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <UserIcon />
            </Box>
            <div>
              <Typography color="primary" variant="body1">
                Dane kontaktowe:
              </Typography>
              {state.loading && (
                <>
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                </>
              )}
              {!!state.data && (
                <>
                  <Typography variant="body1">{state.data.data.contact.data.name}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.address}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.postalCode}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.phone}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.komorka}</Typography>
                  <Typography
                    variant="body1"
                    component="a"
                    href={`mailto:${state.data.data.contact.data.email}`}
                    sx={{
                      fontWeight: 'bold',
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                    {state.data.data.contact.data.email}
                  </Typography>
                  <Typography variant="body1" marginTop="16px">{state.data.data.contact.data.krs}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.nip}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.regon}</Typography>
                </>
              )}
            </div>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'min-content auto',
              alignItems: 'start',
              justifyItems: 'start',
              gap: '16px',
              '& p': {
                lineHeight: 1.2,
                fontWeight: 'bold',
              },
            }}
          >
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '4px',
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ClockIcon />
            </Box>
            <div>
              <Typography color="primary" variant="body1">
                Godziny otwarcia:
              </Typography>
              {state.loading && <Skeleton variant="text" width="100%" />}
              {!!state.data && <Typography variant="body1">{state.data.data.contact.data.openingHours}</Typography>}
            </div>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'min-content auto',
              alignItems: 'start',
              justifyItems: 'start',
              gap: '16px',
              '& p': {
                lineHeight: 1.2,
                fontWeight: 'bold',
              },
            }}
          >
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '4px',
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CreditCardIcon />
            </Box>
            <div>
              <Typography color="primary" variant="body1">
                Wspieraj nas:
              </Typography>
              {state.loading && <Skeleton variant="text" width="100%" />}
              {!!state.data && (
                <Typography variant="body1">nr konta {state.data.data.contact.data.bankAccount}</Typography>
              )}
            </div>
          </Box>
        </Box>
      </Box>
      {/*site owner, creator, privacy policy and social media area*/}
      <Box
        sx={{
          borderTop: '1px solid #D1D1D1',
          display: 'grid',
          gridTemplateAreas: '"typo-title" "private-policy" "icons" "creator-sign"',
          gap: '16px',
          paddingTop: '16px',
          marginTop: '16px',
          alignItems: 'center',
          justifyItems: 'start',
          '@media screen and (min-width: 370px)': {
            gridTemplateAreas: '"typo-title typo-title" "private-policy private-policy" "icons creator-sign"',
          },
          '@media screen and (min-width: 520px)': {
            gridTemplateAreas: '"typo-title private-policy" "icons creator-sign"',
          },
          '@media screen and (min-width: 840px)': {
            gridTemplateAreas: '"typo-title private-policy icons creator-sign"',
            gap: '32px',
          },
          '& .typo-title': {
            gridArea: 'typo-title',
          },
          '& .private-policy': {
            gridArea: 'private-policy',
            justifySelf: 'end',
          },
          '& .icons': {
            gridArea: 'icons',
          },
          '& .creator-sign': {
            gridArea: 'creator-sign',
            justifySelf: 'end',
          },
        }}
      >
        <Typography className="typo-title" variant="body1">
          Stowarzyszenie{' '}
          <Box component="span" sx={{ color: theme.palette.primary.main }}>
            Od-Do
          </Box>{' '}
          2022
        </Typography>
        <Button
          className="private-policy"
          variant="contained"
          color="primary"
          component={Link}
          to="/polityka-prywatnosci"
        >
          Polityka prywatności
        </Button>
        <Box
          className="icons"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            '& > *:first-child': {
              marginRight: '16px',
            },
          }}
        >
          <IconButton href={state.data?.data?.links?.contact?.linkFacebook || ''} target="_blank">
            <FacebookIcon />
          </IconButton>
          <IconButton href={state.data?.data?.links?.contact?.linkInstagram || ''} target="_blank">
            <InstagramIcon />
          </IconButton>
        </Box>
        <Typography className="creator-sign" variant="body1">
          Wdrożenie:{' '}
          <Box
            component="a"
            sx={{
              fontWeight: 'bold',
              textDecoration: 'none',
              color: theme.palette.primary.main,
            }}
            href="https://fastsite.pl/"
            rel="noreferrer"
            target="_blank"
          >
            FASTSITE
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}
