export const about = `{
  users: posts(where: {categoryName: "Pracownik"}, first: 9999) {
    edges {
      node {
        id
        title
        entry {
          description
          position
          image {
            sourceUrl
          }
        }
      }
    }
  }
}`;
