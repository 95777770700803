export function offer(id: string) {
  return `{
  page(id: "${id}") {
    id
    offer {
      description
    }
    title
    featuredImage {
      node {
        sourceUrl
      }
    }
    content
  }
}`;
}
