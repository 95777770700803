import React from 'react';
import { useMediaQuery, Box } from '@mui/material';
import { ReactComponent as Logo } from '../../media/icons/logo.svg';
import { NavLink } from 'react-router-dom';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';
import { Container } from '@mui/system';

export function Header() {
  const isDesktop = useMediaQuery('(min-width: 920px)');

  return (

  <>
  {/* <Box style={{backgroundColor: '#E67300', width: '100%'}}>
    <Container><p style={{color: '#ffffff', fontWeight: '700'}}>WAŻNA INFORMACJA: W związku z tymczasową awarią formularzy na stronie, wszystkich, którzy chcieliby się z nami skontaktować lub próbowali zrobić to w ostatnim czasie, uprzejmie prosimy o korzystanie z numeru kontaktowego (22 632 09 09 oraz 604 485 045) lub adresu email (od-do@od-do.org).</p></Container>
  </Box> */}
      <Box
      sx={{
        maxWidth: '1144px',
        width: '100%',
        height: '82px',
        marginTop: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        paddingRight: '16px',
        paddingLeft: '16px',
      }}
    >
      <NavLink to="/">
        <Logo />
      </NavLink>
      {isDesktop ? <DesktopHeader /> : <MobileHeader />}
    </Box>
  </>

  );
}
