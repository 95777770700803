export const projects = `{
  projects: posts(where: {categoryName: "Projekt"}, first: 9999) {
    edges {
      node {
        id
        title
        entry {
          description
          image {
            sourceUrl
          }
        }
      }
    }
  }
}`;
