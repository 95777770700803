import React, { useCallback, useState } from 'react';
import { ReactComponent as MenuIcon } from '../../media/icons/menu-bars.svg';
import { Button, Card, Popover, PopoverOrigin, Typography, Box, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';

const anchorOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};

const transformOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'top',
};

export function MobileHeader() {
  const theme = useTheme();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);

  const openPopover = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  }, []);

  const closePopover = useCallback(() => {
    setPopoverAnchorEl(null);
  }, []);

  return (
    <>
      <Button
        onClick={openPopover}
        variant="contained"
        color="primary"
        endIcon={
          <Box
            component={MenuIcon}
            sx={{
              width: 20,
              height: 20,
            }}
          />
        }
      >
        Menu
      </Button>
      <Popover
        open={!!popoverAnchorEl}
        onClose={closePopover}
        anchorEl={popoverAnchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Card
          sx={{
            boxSizing: 'border-box',
            padding: '24px',
            display: 'grid',
            gap: '16px',
          }}
        >
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/kim-jestesmy"
          >
            <Typography variant="body1">O nas</Typography>
          </Box>
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/psychoterapia"
          >
            <Typography variant="body1">Psychoterapia</Typography>
          </Box>
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/psychoterapia/mdda"
          >
            <Typography variant="body1">MDDA 18 - 26 lat</Typography>
          </Box>
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/psychoterapia/ddaplus"
          >
            <Typography variant="body1">DDAplus 27+</Typography>
          </Box>
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/psychoterapia/psychoterapia-indywidualna"
          >
            <Typography variant="body1">Psychoterapia indywidualna</Typography>
          </Box>
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/projekty"
          >
            <Typography variant="body1">Projekty</Typography>
          </Box>
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/aktualnosci"
          >
            <Typography variant="body1">Aktualności</Typography>
          </Box>
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/media"
          >
            <Typography variant="body1">Media</Typography>
          </Box>
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/kontakt"
          >
            <Typography variant="body1">Kontakt</Typography>
          </Box>
          <Box
            component={NavLink}
            sx={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            onClick={closePopover}
            to="/wspieraj-nas"
          >
            <Button color="primary" variant="contained">
              Wspieraj nas
            </Button>
          </Box>
        </Card>
      </Popover>
    </>
  );
}
