export const whatIsPsychotherapy = `{
  page(id: "cG9zdDo0NzM=") {
    content
    title
    featuredImage {
      node {
        sourceUrl
      }
    }
  }
}`;
