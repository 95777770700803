export function article(id: string) {
  return `{
  post(id: "${id}") {
    entry {
      publicationDate
      image {
        sourceUrl
      }
    }
    content
    title
    categories {
      nodes {
        name
      }
    }
  }
}`;
}
