import React, { useMemo } from 'react';
import { Button, Typography, Box, useTheme } from '@mui/material';
import { LandingOfferInterface } from '../../api/types/LandingTypes';
import schoolImg from '../../media/images/dda_school.png';
import { TileDataInterface } from '../Tiles/Tile';
import { Tiles } from '../Tiles';

interface OfferPropsInterface {
  psychotherapy?: LandingOfferInterface;
  aca?: LandingOfferInterface;
  acaplus?: LandingOfferInterface;
  schoolLink: string;
}

export function Offer(props: OfferPropsInterface) {
  const theme = useTheme();

  const data: TileDataInterface[] = useMemo(
    () => [
      {
        title: props.aca?.title || '',
        text: props.aca?.offer?.description || '',
        image: props.aca?.featuredImage?.node?.sourceUrl,
        background: true,
        link: '/psychoterapia/mdda',
      },
      {
        title: props.acaplus?.title || '',
        text: props.acaplus?.offer?.description || '',
        image: props.acaplus?.featuredImage?.node?.sourceUrl,
        background: true,
        link: '/psychoterapia/ddaplus',
      },
      {
        title: props.psychotherapy?.title || '',
        text: props.psychotherapy?.offer?.description || '',
        image: props.psychotherapy?.featuredImage?.node?.sourceUrl,
        background: true,
        link: '/psychoterapia/psychoterapia-indywidualna',
      },
    ],
    [props.aca, props.acaplus, props.psychotherapy]
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        boxSizing: 'border-box',
        padding: '32px 16px',
        maxWidth: '1272px',
        width: '100%',
        '@media screen and (min-width: 1200px)': {
          padding: '32px 96px',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          justifyItems: 'start',
          gridTemplateColumns: '1fr',
          gap: '16px',
          marginBottom: '32px',
          '@media screen and (min-width: 500px)': {
            gridTemplateColumns: '1fr auto',
          },
        }}
      >
        <Typography color="secondary" variant="h4">
          Psychoterapia
        </Typography>
      </Box>
      <Tiles data={data} />
      <Box
        sx={{
          gridTemplateColumns: '1fr',
          marginTop: '48px',
          gap: '48px',
          display: 'grid',
          '@media screen and (min-width: 1100px)': {
            gridTemplateColumns: '1fr 1fr 1fr',
            marginTop: '16px',
            gap: '16px',
          },
        }}
      >
        <Box
          component="img"
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '3px',
            gridColumn: '1/2',
            backgroundColor: 'white',
          }}
          width={355}
          height={177}
          alt=""
          src={schoolImg}
        />
        <Box
          sx={{
            padding: '16px',
            boxSizing: 'border-box',
            gridColumn: 'auto',
            borderRadius: '3px',
            backgroundColor: theme.palette.background.paper,
            display: 'grid',
            gap: '24px',
            justifyItems: 'start',
            '@media screen and (min-width: 1100px)': {
              gridColumn: '2/4',
            },
          }}
        >
          <Typography variant="h4" color="primary">
            Szkoła Psychoterapii DDA
          </Typography>
          <Typography color="textPrimary" variant="body1">
            Prowadzimy pierwszą w Polsce Profesjonalna Szkoła Psychoterapii DDA, nastawioną na podniesienie kwalifikacji
            specjalistów, którzy pracują z osobami dotkniętymi skutkami dziecięcej traumy, DDA/DDD.
          </Typography>
          <Button variant="contained" color="primary" href={props.schoolLink} target="_blank">
            przejdź
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
