export const privacy = `{
  page(id: "cG9zdDoz") {
    content
    featuredImage {
      node {
        sourceUrl
      }
    }
  }
}`;
