import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, useMediaQuery, Box } from '@mui/material';
import cure from '../media/images/crue.png';
import { WhoWeAre } from '../components/about/WhoWeAre';
import { Team } from '../components/about/Team';
import { AboutResponseInterface } from '../api/types/AboutTypes';
import { http } from '../api/http';
import { about } from '../api/queries/about';
import { Error } from '../components/Error';
import { OpinionsSlider } from '../components/OpinionsSlider';
import { API } from '../constants';

interface StateInterface {
  data: AboutResponseInterface | null;
  loading: boolean;
  error: boolean;
}

export function About() {
  const [state, setState] = useState<StateInterface>({
    data: null,
    loading: true,
    error: false,
  });

  const isDesktop = useMediaQuery('(min-width: 500px)');

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: null,
      loading: true,
      error: false,
    }));

    http<AboutResponseInterface>(about, { signal: controller.signal })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        if (controller.signal.aborted) return;

        setState((prev) => ({
          ...prev,
          data: null,
          loading: false,
          error: true,
        }));
      });

    return () => controller.abort();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      {state.loading && !state.error && <CircularProgress sx={{ width: '16px', height: '16px' }} size={100} />}
      {state.error && !state.loading && <Error />}
      {!!state.data && (
        <>
          <Typography
            sx={{
              marginTop: '16px',
              marginBottom: '40px',
            }}
            variant={isDesktop ? 'h2' : 'h3'}
          >
            O nas
          </Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              borderRadius: '3px',
              maxWidth: '1084px',
            }}
            width={1166}
            height={524}
            alt=""
            src={cure}
          />
          <WhoWeAre />
          <OpinionsSlider />
          <Team data={state.data.data.users.edges} />
          {/* COWORKING */}
          <Typography
            sx={{
              marginBottom: '56px',
            }}
            variant="h2"
          >
            Współpracują z nami
          </Typography>
          <Box
            component="img"
            src={`${API}/wp-content/uploads/2022/05/zdjecie-nr-2-2-scaled.jpg`}
            sx={{
              height: '200px',
              width: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
              '@media screen and (min-width: 600px)': {
                height: '400px',
              },
              '@media screen and (min-width: 400px)': {
                height: '550px',
              },
            }}
          />
          <Box
            sx={{
              marginTop: '56px',
              maxWidth: '1112px',
              width: '100%',
              padding: '16px',
              backgroundColor: '#F3F3F3',
              boxSizing: 'border-box',
              borderRadius: '3px',
              '@media screen and (min-width: 670px)': {
                padding: '56px',
              },
            }}
          >
            <Typography variant="body1">
              Jako Stowarzyszenie jesteśmy otwarci na różne formy współpracy z wieloma specjalistami. Takimi osobami są
              m. in. Anna Andraszek, Joanna Wiśniewska, Anna Szczycińska, Katarzyna Burzyńska, Marta de Laurans i Marcin
              Gała.{' '}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
