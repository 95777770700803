export const media = `{
  media: posts(where: {categoryName: "Media"}, first: 9999) {
    edges {
      node {
        id
        title
        tags {
          edges {
            node {
              name
            }
          }
        }
        entry {
          description
          publicationDate
          link
          image {
            sourceUrl
          }
        }
      }
    }
  }
}`;
