import React from 'react';
import { Skeleton, Typography, useMediaQuery, Box, useTheme } from '@mui/material';
import { ReactComponent as ClockIcon } from '../media/icons/clock.svg';
import { formatDate } from '../helpers/formatDate';

interface ArticlePropsInterface {
  content: string;
  title?: string;
  loading: boolean;
  image?: string;
  employeeImage?: boolean;
  date?: string;
  hideDate?: boolean;
}

export function ArticleComponent(props: ArticlePropsInterface) {
  const theme = useTheme();
  const isDesktop = useMediaQuery('(min-width: 500px)');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        boxSizing: 'border-box',
        maxWidth: '966px',
        paddingRight: '16px',
        paddingLeft: '16px',
      }}
    >
      {props.loading ? (
        <>
          <Box
            sx={{
              marginTop: '40px',
              marginBottom: '24px',
              width: '100%',
              height: '500px',
              boxSizing: 'border-box',
              position: 'relative',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '3px',
            }}
          >
            <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />
          </Box>
          <Skeleton animation="wave" width="50%">
            <Typography
              sx={{
                zIndex: 1,
                color: 'black',
                maxWidth: '966px',
                width: '100%',
                marginTop: '16px',
                marginBottom: '16px',
                fontWeight: 'bold',
                boxSizing: 'border-box',
                padding: '16px',
              }}
              variant="h3"
            >
              Skeleton
            </Typography>
          </Skeleton>
        </>
      ) : (
        <>
          {props.image !== undefined && (
            <Box
              sx={{
                marginTop: '40px',
                marginBottom: '24px',
                width: '100%',
                height: '500px',
                boxSizing: 'border-box',
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '3px',
              }}
            >
              {!!props.employeeImage ? (
                <Box
                  component="img"
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  width={1166}
                  height={874.5}
                  src={props.image}
                  alt=""
                />
              ) : (
                <Box
                  component="img"
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    border: `4px solid ${theme.palette.primary.main}`,
                    borderRadius: '3px',
                  }}
                  width={1920}
                  height={1080}
                  src={props.image}
                  alt=""
                />
              )}
            </Box>
          )}
          {props.title !== undefined && <Typography variant={isDesktop ? 'h3' : 'h4'}>{props.title}</Typography>}
          {props.date !== undefined && !props.hideDate && (
            <Box
              sx={{
                width: '100%',
                maxWidth: '982px',
                paddingRight: '16px',
                paddingLeft: '16px',
                marginBottom: '4px',
                display: 'flex',
                alignItems: 'center',
                '& > *:first-child': {
                  marginRight: '16px',
                },
              }}
            >
              <ClockIcon />
              <Typography>{formatDate(props.date)}</Typography>
            </Box>
          )}
          <Box
            sx={{
              maxWidth: 950,
              width: '100%',
              boxSizing: 'border-box',
              padding: '16px',
              backgroundColor: '#F3F3F3',
              borderRadius: 3,
              '@media screen and (min-width: 650px)': {
                padding: '32px',
              },
              '& img': {
                maxWidth: '100%',
                objectFit: 'contain',
                height: 'auto',
              },
              '& a': {
                color: theme.palette.primary.main,
              },
            }}
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        </>
      )}
    </Box>
  );
}
