export const news = `{
  news: posts(where: {categoryName: "News"}, first: 9999) {
    edges {
      node {
        id
        title
        entry {
          description
          publicationDate
          image {
            sourceUrl
          }
        }
      }
    }
  }
}`;
