import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { TileDataInterface } from './Tile';
import { useMediaQuery, Box } from '@mui/material';
import { TilesRow } from './TilesRow';

interface TilesPropsInterface {
  data: TileDataInterface[];
}

export function Tiles(props: TilesPropsInterface) {
  const [columns, setColumns] = useState<number>(1);
  const mediumScreen = useMediaQuery('(min-width: 680px)');
  const bigScreen = useMediaQuery('(min-width: 1100px)');

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const observer = new ResizeObserver(() => {
      if (bigScreen) {
        setColumns(3);
        return;
      }
      if (mediumScreen) {
        setColumns(2);
        return;
      }
      setColumns(1);
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [bigScreen, mediumScreen]);

  const data = useMemo<TileDataInterface[][]>(() => {
    const copiedData = [...props.data];
    const toReturn: TileDataInterface[][] = [];

    for (let i = 0; i < copiedData.length; i + columns) {
      const chunk = copiedData.splice(i, columns);
      toReturn.push(chunk);
    }

    return toReturn;
  }, [props.data, columns]);

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '48px',
      }}
      ref={ref}
    >
      {data.map((item, index) => (
        <TilesRow key={index} data={item} columns={columns} />
      ))}
    </Box>
  );
}
