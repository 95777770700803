import React from 'react';
import { Typography, Box } from '@mui/material';

export function WhoWeAre() {
  return (
    <Box
      sx={{
        marginTop: '56px',
        maxWidth: '1112px',
        width: '100%',
        padding: '16px',
        backgroundColor: '#F3F3F3',
        boxSizing: 'border-box',
        borderRadius: '3px',
        '@media screen and (min-width: 670px)': {
          padding: '56px',
        },
      }}
    >
      <Typography
        sx={{
          marginBottom: '56px',
        }}
        variant="h3"
      >
        Kim jesteśmy?
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: '16px',
        }}
      >
        <Typography variant="h5" color="primary">
          Jak powstało Stowarzyszenie OD-DO?
        </Typography>
        <Typography variant="body1">
          Wszystko zaczęło się od studiów w Instytucie Profilaktyki Społecznej i Resocjalizacji na Uniwersytecie
          Warszawskim. Tam się poznaliśmy i zaprzyjaźniliśmy. Na pewnym etapie naszego życia zawodowego stwierdziliśmy,
          że chcemy też stworzyć razem zespół psychoterapeutów. Zdecydowaliśmy się wspólnie podjąć działania adresowane
          do młodych osób wzrastających w rodzinach dysfunkcjonalnych.
        </Typography>
      </Box>
      <Box
        sx={{
          height: 1,
          backgroundColor: 'white',
          width: '100%',
          marginTop: '56px',
          marginBottom: '56px',
        }}
      />
      <Box
        sx={{
          display: 'grid',
          gap: '16px',
        }}
      >
        <Typography variant="h5" color="primary">
          Gdzie i z kim działamy?
        </Typography>
        <Typography variant="body1">
          Istniejemy jako Stowarzyszenie OD-DO od 19 października 2001 r. Działając na warszawskiej Woli współtworzymy
          sieć organizacji pozarządowych stolicy. Pozostajemy we współpracy m.in. z wolskim Ośrodkiem Pomocy Społecznej,
          Urzędem Dzielnicy Wola oraz z Urzędem m.st. Warszawa.
        </Typography>
        <Typography variant="body1">
          Stowarzyszenie od 2006 roku figuruje w rejestrze instytucji szkoleniowych Mazowieckiego Wojewódzkiego Urzędu
          Pracy (nr ewidencyjny: 2.14/00124/2017).
        </Typography>
      </Box>
      <Box
        sx={{
          height: 1,
          backgroundColor: 'white',
          width: '100%',
          marginTop: '56px',
          marginBottom: '56px',
        }}
      />
      <Box
        sx={{
          display: 'grid',
          gap: '16px',
        }}
      >
        <Typography variant="h5" color="primary">
          Co oferujemy?
        </Typography>
        <Typography variant="body1">
          Nasz zespół łączy wspólny cel: chcemy prowadzić i wspierać osoby z syndromem DDA/DDD na drodze do odkrywania i
          akceptowania siebie, dokonywania własnych wyborów oraz do brania odpowiedzialności za swoje życie.
        </Typography>
        <Typography variant="body1">
          Mamy wieloletnie doświadczenie w pracy psychoterapeutycznej, dzięki któremu stworzyliśmy autorską ofertę
          terapeutyczną dla osób z syndromem DDA/DDD (syndrom towarzyszący dorosłym dzieciom z rodzin
          dysfunkcjonalnych). Na bazie tego doświadczenia powstała Szkoła Psychoterapii DDA, w której wykorzystujemy
          dorobek autorskich szkoleń dotyczących pracy z osobami z syndromem DDA/DDD. Uczymy na najwyższym poziomie
          merytorycznym m.in. jak korzystać z naszej metody prowadzenia terapii indywidualnej i grupowej, opartej na
          budowaniu tożsamości każdego z uczestników jako terapeuty. Naszą ofertę dopełniają szkolenia w zakresie
          kompetencji społecznych.{' '}
        </Typography>
      </Box>
    </Box>
  );
}
