import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, useMediaQuery, Box } from '@mui/material';
import { http } from '../api/http';
import { MediaResponseInterface } from '../api/types/MediaTypes';
import { media } from '../api/queries/media';
import { Error } from '../components/Error';
import { TileDataInterface } from '../components/Tiles/Tile';
import { Tiles } from '../components/Tiles';

interface StateInterface {
  data: TileDataInterface[];
  loading: boolean;
  error: boolean;
}

export function Media() {
  const [state, setState] = useState<StateInterface>({
    data: [],
    loading: false,
    error: false,
  });

  const isDesktop = useMediaQuery('(min-width: 500px)');

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: [],
      loading: true,
      error: false,
    }));

    http<MediaResponseInterface>(media, { signal: controller.signal })
      .then((res) => {
        const data: TileDataInterface[] = (res.data?.media?.edges || []).map((item) => ({
          text: item.node?.entry?.description || '',
          title: item.node?.title || '',
          linkOutside: item.node?.entry?.link,
          buttonText: !!item.node?.entry?.link ? 'przejdź' : 'czytaj więcej',
          id: item.node?.id,
          image: item.node?.entry?.image?.sourceUrl || '',
          date: item.node?.entry?.publicationDate,
          tags: (item.node?.tags?.edges || []).map((i) => i.node?.name || ''),
        }));

        setState((prev) => ({
          ...prev,
          data,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        if (controller.signal.aborted) return;

        setState((prev) => ({
          ...prev,
          data: [],
          error: true,
          loading: false,
        }));
      });

    return () => controller.abort();
  }, []);

  if (state.error) {
    return <Error />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      {state.loading && <CircularProgress sx={{ margin: '16px 0' }} size={100} />}
      {!state.loading && (
        <Box
          sx={{
            maxWidth: '1144px',
            width: '100%',
            boxSizing: 'border-box',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '56px',
              marginBottom: '56px',
            }}
          >
            <Typography variant={isDesktop ? 'h2' : 'h3'}>Media</Typography>
          </Box>
          {state.data.length > 0 ? (
            <Tiles data={state.data} />
          ) : (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                boxSizing: 'border-box',
                padding: '16px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h4" color="primary">
                Brak aktualnych mediów
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
