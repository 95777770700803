import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Typography, useMediaQuery, Box } from '@mui/material';
import { http } from '../api/http';
import { psychotherapy } from '../api/queries/psychotherapy';
import { PsychotherapyResponseInterface } from '../api/types/PsychotherapyTypes';
import schoolImage from '../media/images/school.png';
import { Error } from '../components/Error';
import { TileDataInterface } from '../components/Tiles/Tile';
import { Tiles } from '../components/Tiles';

interface StateInterface {
  data: TileDataInterface[];
  schoolLink: string;
  loading: boolean;
  error: boolean;
}

export function Psychotherapy() {
  const [state, setState] = useState<StateInterface>({
    data: [],
    schoolLink: '',
    loading: false,
    error: false,
  });

  const isDesktop = useMediaQuery('(min-width: 500px)');
  const textColor = useMediaQuery('(min-width: 530px)');

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: [],
      loading: true,
      error: false,
    }));

    http<PsychotherapyResponseInterface>(psychotherapy, { signal: controller.signal })
      .then((res) => {
        const data: TileDataInterface[] = [
          {
            title: res.data?.aca?.title || '',
            text: res.data?.aca?.offer?.description || '',
            image: res.data?.aca?.featuredImage?.node?.sourceUrl || '',
            link: '/psychoterapia/mdda',
          },
          {
            title: res.data?.acaplus?.title || '',
            text: res.data?.acaplus?.offer?.description || '',
            image: res.data?.acaplus?.featuredImage?.node?.sourceUrl || '',
            link: '/psychoterapia/ddaplus',
          },
          {
            title: res.data?.psychotherapy?.title || '',
            text: res.data?.psychotherapy?.offer?.description || '',
            image: res.data?.psychotherapy?.featuredImage?.node?.sourceUrl || '',
            link: '/psychoterapia/psychoterapia-indywidualna',
          },
        ];

        const schoolLink = res.data?.links?.contact?.schoolLink || '';

        setState((prev) => ({
          ...prev,
          data,
          loading: false,
          error: false,
          schoolLink,
        }));
      })
      .catch(() => {
        if (controller.signal.aborted) return;

        setState((prev) => ({
          ...prev,
          data: [],
          error: true,
          loading: false,
        }));
      });

    return () => controller.abort();
  }, []);

  if (state.error) {
    return <Error />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      {state.loading && (
        <CircularProgress
          sx={{
            marginTop: '16px',
            marginBottom: '16px',
          }}
          size={100}
        />
      )}
      {!state.loading && (
        <>
          <Box
            sx={{
              maxWidth: '1144px',
              width: '100%',
              marginBottom: '56px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '56px',
                marginBottom: '56px',
              }}
            >
              <Typography variant={isDesktop ? 'h2' : 'h3'}>Psychoterapia</Typography>
            </Box>
            <Box
              sx={{
                maxWidth: '1112px',
                width: '100%',
                boxSizing: 'border-box',
                paddingLeft: '16px',
                paddingRight: '16px',
              }}
            >
              <Tiles data={state.data} />
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: '1112px',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
              height: '800px',
              marginBottom: '32px',
              borderRadius: '3px',
              '@media screen and (min-width: 530px)': {
                height: '502px',
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'left',
                display: 'none',
                '@media screen and (min-width: 530px)': {
                  display: 'initial',
                },
              }}
              width={1166}
              height={502}
              alt=""
              src={schoolImage}
            />
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: '#F3F3F3',
                top: 0,
                left: 0,
                zIndex: 1,
                '@media screen and (min-width: 530px)': {
                  background: 'rgba(0,0,0,0.5)',
                },
                '@media screen and (min-width: 1100px)': {
                  background:
                    'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(1,0,20,0) 25%, rgba(0,0,0,0.6) 51%, rgba(0,0,0,1) 100%)',
                },
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: 2,
                boxSizing: 'border-box',
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'start',
                '@media screen and (min-width: 1100px)': {
                  padding: '32px 32px 32px 50%',
                },
              }}
            >
              <Typography variant="h4" color={textColor ? 'secondary' : 'textPrimary'}>
                Szkoła psychoterapii
              </Typography>
              <Typography variant="body1" color={textColor ? 'secondary' : 'textPrimary'}>
                Szkoła Psychoterapii DDA - od syndromu do źródła jest całościowym i wysoko specjalistycznym szkoleniem
                zawodowym. Celem Szkoły jest wszechstronne przygotowanie do samodzielnego i profesjonalnego uprawiania
                psychoterapii w myśl podejścia integratywnego twórczo łączącego dorobek istniejących szkół
                psychoterapeutycznych, na użytek osobistej praktyki w pracy z osobami dotkniętymi skutkami dziecięcej
                traumy relacyjnej związanej z wzrastaniem w rodzinie dysfunkcjonalnej (syndrom DDA/DDD). Szkoła uczy
                praktycznych umiejętności diagnozy i pracy terapeutycznej zarówno grupowej, jak i indywidualnej.
                Skuteczność metody pracy z DDA/DDD jest zweryfikowana ponad 20-letnią pracą kliniczną autorów. Jest to
                pierwsza w Polsce Profesjonalna Szkoła Psychoterapii DDA, nastawiona na podniesienie kwalifikacji
                specjalistów, którzy pracują z osobami z syndromem DDA/DDD.
              </Typography>
              <Button variant="contained" color="primary" href={state.schoolLink} target="_blank">
                przejdź na stronę
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
