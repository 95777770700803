import React, { useEffect, useState } from 'react';
import { http } from '../api/http';
import { PrivacyResponseInterface } from '../api/types/PrivacyTypes';
import { privacy } from '../api/queries/privacy';
import { CircularProgress, Typography, Box, useTheme } from '@mui/material';
import { Error } from '../components/Error';

interface StateInterface {
  data: PrivacyResponseInterface | null;
  loading: boolean;
  error: boolean;
}

export function Privacy() {
  const theme = useTheme();
  const [state, setState] = useState<StateInterface>({
    data: null,
    error: false,
    loading: true,
  });

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: null,
      loading: true,
      error: false,
    }));

    http<PrivacyResponseInterface>(privacy, { signal: controller.signal })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        if (controller.signal.aborted) return;

        setState((prev) => ({
          ...prev,
          data: null,
          loading: false,
          error: true,
        }));
      });

    return () => controller.abort();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      {state.loading && !state.error && <CircularProgress sx={{ margin: '16px 0' }} size={100} />}
      {state.error && !state.loading && <Error />}
      {!!state.data && (
        <>
          <Box
            sx={{
              marginTop: '40px',
              marginBottom: '24px',
              maxWidth: '1112px',
              width: '100%',
              height: '500px',
              paddingRight: '16px',
              paddingLeft: '16px',
              boxSizing: 'border-box',
              position: 'relative',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '3px',
            }}
          >
            <Box
              component="img"
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              width={1920}
              height={1080}
              src={state.data.data.page.featuredImage.node.sourceUrl}
              alt=""
            />
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                background: 'radial-gradient(circle, rgba(2,0,36,0.4) 0%, rgba(0,0,0,0.6) 51%, rgba(0,0,0,1) 100%)',
              }}
            />
            <Typography
              sx={{
                zIndex: 1,
                color: 'white',
                textAlign: 'center',
              }}
              variant="h3"
            >
              Polityka prywatności
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: '950px',
              width: '100%',
              boxSizing: 'border-box',
              padding: '16px',
              backgroundColor: '#F3F3F3',
              borderRadius: '3px',
              '@media screen and (min-width: 650px)': {
                padding: '24px',
              },
              '& img': {
                maxWidth: '100%',
                objectFit: 'contain',
                height: 'auto',
              },
              '& a': {
                color: theme.palette.primary.main,
              },
            }}
            dangerouslySetInnerHTML={{ __html: state.data.data.page.content }}
          />
        </>
      )}
    </Box>
  );
}
