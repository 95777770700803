import React, { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Header } from './components/Header/Header';
import { ThemeProvider } from './global/ThemeProvider';

export function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [pathname]);

  return (
    <ThemeProvider>
      <Header />
      <Outlet />
      <Footer />
    </ThemeProvider>
  );
}
