import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, IconButton, Popover, PopoverOrigin, Typography, Box, useTheme } from '@mui/material';
import { ReactComponent as ArrowDown } from '../../media/icons/arrow-down.svg';

const anchorOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};

const transformOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'top',
};

export function DesktopHeader() {
  const theme = useTheme();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);

  const openPopover = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  }, []);

  const closePopover = useCallback(() => {
    setPopoverAnchorEl(null);
  }, []);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '45px 147px repeat(4, min-content) 142px',
        gap: '24px',
        alignItems: 'center',
        justifyItems: 'start',
      }}
    >
      <Box
        component={NavLink}
        sx={{
          color: theme.palette.text.primary,
          textDecoration: 'none',
        }}
        to="/kim-jestesmy"
      >
        <Typography variant="body1">O nas</Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'min-content min-content',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <Box
          component={NavLink}
          sx={{
            color: theme.palette.text.primary,
            textDecoration: 'none',
          }}
          to="/psychoterapia"
        >
          <Typography variant="body1">Psychoterapia</Typography>
        </Box>
        <IconButton
          sx={{
            height: '35px',
            width: '35px',
          }}
          onClick={openPopover}
        >
          <ArrowDown />
        </IconButton>
        <Popover
          open={!!popoverAnchorEl}
          onClose={closePopover}
          anchorEl={popoverAnchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <Card
            sx={{
              boxSizing: 'border-box',
              padding: '24px',
              display: 'grid',
              gap: '16px',
            }}
          >
            <Box
              component={NavLink}
              sx={{
                color: theme.palette.text.primary,
                textDecoration: 'none',
              }}
              onClick={closePopover}
              to="/psychoterapia/mdda"
            >
              <Typography variant="body1">MDDA 18 - 26 lat</Typography>
            </Box>
            <Box
              component={NavLink}
              sx={{
                color: theme.palette.text.primary,
                textDecoration: 'none',
              }}
              onClick={closePopover}
              to="/psychoterapia/ddaplus"
            >
              <Typography variant="body1">DDAplus 27+</Typography>
            </Box>
            <Box
              component={NavLink}
              sx={{
                color: theme.palette.text.primary,
                textDecoration: 'none',
              }}
              onClick={closePopover}
              to="/psychoterapia/psychoterapia-indywidualna"
            >
              <Typography variant="body1">Psychoterapia indywidualna</Typography>
            </Box>
          </Card>
        </Popover>
      </Box>
      <Box
        component={NavLink}
        sx={{
          color: theme.palette.text.primary,
          textDecoration: 'none',
        }}
        to="/projekty"
      >
        <Typography variant="body1">Projekty</Typography>
      </Box>
      <Box
        component={NavLink}
        sx={{
          color: theme.palette.text.primary,
          textDecoration: 'none',
        }}
        to="/aktualnosci"
      >
        <Typography variant="body1">Aktualności</Typography>
      </Box>
      <Box
        component={NavLink}
        sx={{
          color: theme.palette.text.primary,
          textDecoration: 'none',
        }}
        to="/media"
      >
        <Typography variant="body1">Media</Typography>
      </Box>
      <Box
        component={NavLink}
        sx={{
          color: theme.palette.text.primary,
          textDecoration: 'none',
        }}
        to="/kontakt"
      >
        <Typography variant="body1">Kontakt</Typography>
      </Box>
      <Box
        component={NavLink}
        sx={{
          color: theme.palette.text.primary,
          textDecoration: 'none',
        }}
        to="/wspieraj-nas"
      >
        <Button color="primary" variant="contained">
          Wspieraj nas
        </Button>
      </Box>
    </Box>
  );
}
