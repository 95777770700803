import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Routing } from './Routing';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';

dayjs.locale('pl');

const element = document.getElementById('root')!;

const root = createRoot(element);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <Routing />
      </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>
);
