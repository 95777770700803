export const landing = `{
  psychotherapy: page(id: "cG9zdDoxMzE=") {
    id
    offer {
      description
    }
    title
    featuredImage {
      node {
        sourceUrl
      }
    }
  }
  aca: page(id: "cG9zdDoxMzU=") {
    id
    offer {
      description
    }
    title
    featuredImage {
      node {
        sourceUrl
      }
    }
  }
  acaplus: page(id: "cG9zdDoxMzM=") {
    id
    offer {
      description
    }
    title
    featuredImage {
      node {
        sourceUrl
      }
    }
  }
  links: page(id: "cG9zdDoxMTQ=") {
    contact {
      linkFacebook
      linkInstagram
      schoolLink
    }
  }
  news: posts(where: {categoryName: "News"}, first: 3) {
    edges {
      node {
        id
        title
        entry {
          description
          publicationDate
          image {
            sourceUrl
          }
        }
      }
    }
  }
}`;
