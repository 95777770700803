import React, { useEffect, useState } from 'react';
import { Skeleton, Typography, useMediaQuery, Box } from '@mui/material';
import supportUsImage from '../media/images/support-us.png';
import { SupportUsResponseInterface } from '../api/types/SupportUsTypes';
import { http } from '../api/http';
import { supportUs } from '../api/queries/supportUs';
import { Error } from '../components/Error';

interface StateInterface {
  data: SupportUsResponseInterface | null;
  loading: boolean;
  error: boolean;
}

export function SupportUs() {
  const [state, setState] = useState<StateInterface>({
    data: null,
    loading: false,
    error: false,
  });

  const isDesktop = useMediaQuery('(min-width: 500px)');

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: null,
      loading: true,
      error: false,
    }));

    http<SupportUsResponseInterface>(supportUs, { signal: controller.signal })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          data: null,
          loading: false,
          error: true,
        }));
      });

    return () => controller.abort();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      {state.error ? (
        <Error />
      ) : (
        <Box
          sx={{
            maxWidth: '1112px',
            width: '100%',
            marginBottom: '56px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '56px 16px',
              boxSizing: 'border-box',
            }}
          >
            <Typography textAlign="center" variant={isDesktop ? 'h2' : 'h3'}>
              Wspieraj nas
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gap: '16px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: '100%',
                borderRadius: '3px',
                objectFit: 'cover',
              }}
              alt=""
              src={supportUsImage}
              width={1053}
              height={258}
            />
            <Box
              sx={{
                backgroundColor: '#F3F3F3',
                boxSizing: 'border-box',
                borderRadius: '3px',
                padding: '16px',
              }}
            >
              {state.loading && (
                <>
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="70%" />
                  <br />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="50%" />
                </>
              )}
              {!!state.data && <div dangerouslySetInnerHTML={{ __html: state.data.data.page.content }} />}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
