import React, { useEffect, useState } from 'react';
import { http } from '../api/http';
import { WhoAreAcaResponseInterface } from '../api/types/WhoAreAca';
import { Error } from '../components/Error';
import { ArticleComponent } from '../components/ArticleComponent';
import { whatIsPsychotherapy } from '../api/queries/whatIsPsychotherapy';

interface StateInterface {
  data: WhoAreAcaResponseInterface | null;
  loading: boolean;
  error: boolean;
}

export function WhatIsPsychotherapy() {
  const [state, setState] = useState<StateInterface>({
    data: null,
    error: false,
    loading: true,
  });

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: null,
      loading: true,
      error: false,
    }));

    http<WhoAreAcaResponseInterface>(whatIsPsychotherapy, { signal: controller.signal })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        if (controller.signal.aborted) return;

        setState((prev) => ({
          ...prev,
          data: null,
          loading: false,
          error: true,
        }));
      });

    return () => controller.abort();
  }, []);

  if (state.error) {
    return <Error />;
  }

  return (
    <ArticleComponent
      content={state.data?.data?.page?.content || ''}
      loading={state.loading}
      image={state.data?.data?.page?.featuredImage?.node?.sourceUrl}
      title={state.data?.data?.page?.title}
    />
  );
}
