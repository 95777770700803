import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Tile, TileDataInterface } from './Tile';

export type UpdateItemHeightAction = (height: number, index: number) => void;

function useHandleDataContainerHeight(): [number, UpdateItemHeightAction] {
  const [height, setHeight] = useState<number>(0);

  const ref = useRef<number[]>([]);

  const updateDataHeight: UpdateItemHeightAction = (height, index) => {
    ref.current[index] = height;
    setHeight(Math.max(...ref.current));
  };

  return [height, updateDataHeight];
}

interface TilesRowPropsInterface {
  data: TileDataInterface[];
  columns: number;
}

export function TilesRow(props: TilesRowPropsInterface) {
  const [titleContainerHeight, updateTitleItemHeight] = useHandleDataContainerHeight();
  const [textContainerHeight, updateTextItemHeight] = useHandleDataContainerHeight();

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '16px',
        gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
      }}
    >
      {props.data.map((item, index) => (
        <Tile
          key={index}
          index={index}
          data={item}
          titleContainerHeight={titleContainerHeight}
          updateTitleItemHeight={updateTitleItemHeight}
          textContainerHeight={textContainerHeight}
          updateTextItemHeight={updateTextItemHeight}
        />
      ))}
    </Box>
  );
}
