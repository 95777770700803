import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { http } from '../api/http';
import { article } from '../api/queries/article';
import { ArticleInterface } from '../api/types/ArticleTypes';
import { Error } from '../components/Error';
import { ArticleComponent } from '../components/ArticleComponent';

interface StateInterface {
  data: ArticleInterface | null;
  loading: boolean;
  error: boolean;
}

export function Article() {
  const params = useParams<{ id: string }>();
  const [state, setState] = useState<StateInterface>({
    data: null,
    error: false,
    loading: true,
  });

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: null,
      loading: true,
      error: false,
    }));

    http<ArticleInterface>(article(params.id as string), { signal: controller.signal })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        if (controller.signal.aborted) return;

        setState((prev) => ({
          ...prev,
          data: null,
          loading: false,
          error: true,
        }));
      });

    return () => controller.abort();
  }, [params.id]);

  if (state.error) {
    return <Error />;
  }

  return (
    <ArticleComponent
      content={state.data?.data?.post?.content || ''}
      loading={state.loading}
      employeeImage={state.data?.data?.post?.categories?.nodes[0]?.name === 'Pracownik'}
      image={state.data?.data?.post?.entry?.image?.sourceUrl}
      title={state.data?.data?.post?.title}
      date={state.data?.data?.post?.entry?.publicationDate}
      hideDate={
        state.data?.data?.post?.categories?.nodes[0]?.name === 'Pracownik' ||
        state.data?.data?.post?.categories?.nodes[0]?.name === 'Projekt'
      }
    />
  );
}
