import React from 'react';
import { Typography, Box } from '@mui/material';

export function Error() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '32px',
        marginBottom: '32px',
      }}
    >
      <Typography variant="h4" color="primary" align="center">
        Coś poszło nie tak, spróbuj ponownie później
      </Typography>
    </Box>
  );
}
