import { API } from '../constants';

export function http<T = {}>(query: string, options: RequestInit = {}): Promise<T> {
  return fetch(`${API}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
    ...options,
  }).then((res) => res.json());
}

interface FormHttpParamsInterface {
  username: string;
  email: string;
  phone: string;
  message: string;
  accept: boolean;
}

interface FormHttpCallbacksInterface {
  loadstart: () => void;
  loadend: () => void;
  error: () => void;
}

export function formHttp(params: FormHttpParamsInterface, callbacks: FormHttpCallbacksInterface, id: number = 145) {
  const url = `${API}/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`;

  // const XHR = new XMLHttpRequest();

  // const data = new FormData();
  // data.append('accept', String(params.accept));
  // data.append('email', params.email);
  // data.append('message', params.message);
  // data.append('phone', params.phone);
  // data.append('username', params.username);

  // const urlEncodedDataPairs: string[] = [];
  // let name: keyof FormHttpParamsInterface;

  // for (name in params) {
  //   if (params.hasOwnProperty(name)) {
  //     urlEncodedDataPairs.push(`${encodeURIComponent(name)}=${encodeURIComponent(params[name])}`);
  //   }
  // }

  // const urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

  // XHR.addEventListener('loadstart', callbacks.loadstart);
  // XHR.addEventListener('loadend', callbacks.loadend);
  // XHR.addEventListener('error', callbacks.error);

  // XHR.open('POST', url, true);
  // //XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  // XHR.setRequestHeader('Content-Type', 'multipart/form-data');
  // //XHR.send(urlEncodedData);
  // XHR.send(data);

  var data = new FormData();
  // data.append('accept', String(params.accept));
  // data.append('email', params.email);
  // data.append('message', params.message);
  // data.append('phone', params.phone);
  // data.append('username', params.username);

  let name: keyof FormHttpParamsInterface;

  for (name in params) {
    if (params.hasOwnProperty(name)) {
      data.append(name, String(params[name]));
    }
  }

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.addEventListener('loadstart', callbacks.loadstart);
  xhr.addEventListener('loadend', callbacks.loadend);
  xhr.addEventListener('error', callbacks.error);

  xhr.addEventListener("readystatechange", function() {
    if(this.readyState === 4) {
      console.log(this.responseText);
      const response = JSON.parse(this.responseText);
      console.log(response);

      if(response.status != 'mail_sent'){
        callbacks.error();
      }
    }
  });

  xhr.open("POST", url);

  xhr.send(data);
}
