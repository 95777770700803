import React, { useEffect, useState } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { FirstView } from '../components/landing/FirstView';
import { Offer } from '../components/landing/Offer';
import { http } from '../api/http';
import { landing } from '../api/queries/landing';
import { LandingResponseInterface } from '../api/types/LandingTypes';
import { News } from '../components/landing/News';
import { Error } from '../components/Error';
import { TileDataInterface } from '../components/Tiles/Tile';

interface StateInterface {
  data: LandingResponseInterface | null;
  news: TileDataInterface[];
  loading: boolean;
  error: boolean;
}

export function Landing() {
  const [state, setState] = useState<StateInterface>({
    data: null,
    loading: true,
    error: false,
    news: [],
  });

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: null,
      error: false,
      loading: true,
    }));

    http<LandingResponseInterface>(landing, { signal: controller.signal })
      .then((res) => {
        const news: TileDataInterface[] = (res.data?.news?.edges || []).map((item) => ({
          image: item.node?.entry?.image?.sourceUrl,
          title: item.node?.title,
          text: item.node?.entry?.description,
          buttonText: 'Czytaj więcej',
          border: true,
          id: item.node?.id,
        }));

        setState((prev) => ({
          ...prev,
          data: res,
          error: false,
          loading: false,
          news,
        }));
      })
      .catch(() => {
        if (controller.signal.aborted) return;

        setState((prev) => ({
          ...prev,
          data: null,
          error: true,
          loading: false,
        }));
      });

    return () => controller.abort();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      {state.loading && !state.error && (
        <CircularProgress sx={{ marginTop: '16px', marginBottom: '16px' }} size={100} />
      )}
      {state.error && !state.loading && <Error />}
      {!!state.data && !state.loading && !state.error && (
        <>
          <FirstView
            linkFacebook={state.data.data.links.contact.linkFacebook}
            linkInstagram={state.data.data.links.contact.linkInstagram}
          />
          <Offer
            psychotherapy={state?.data?.data?.psychotherapy}
            aca={state?.data?.data?.aca}
            acaplus={state?.data?.data?.acaplus}
            schoolLink={state.data.data.links.contact.schoolLink}
          />
          {state.news.length > 0 && <News data={state.news} />}
        </>
      )}
    </Box>
  );
}
