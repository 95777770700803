import React, { useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formHttp } from '../api/http';
import { useSnackbar } from 'notistack';

const schema = yup.object().shape({
  username: yup.string().required('To pole jest wymagane.'),
  age: yup.number().typeError('Wartość pola musi być liczbą.').required('To pole jest wymagane.'),
  email: yup.string().email('Nieprawidłowy adres mail.').required('To pole jest wymagane.'),
  phone: yup.string().required('To pole jest wymagane.'),
  message: yup.string().required('To pole jest wymagane.'),
  fromWhereYouKnow: yup.string(),
  accept: yup
    .bool()
    .oneOf([true], 'Bez wyrażenia zgody nie możemy przyjąć formularza.')
    .required('Bez wyrażenia zgody nie możemy przyjąć formularza.'),
});

interface OfferFormPropsInterface {
  type: 'psychoterapia-indywidualna' | 'mdda' | 'ddaplus';
}

interface FormInterface {
  username: string;
  age: number;
  email: string;
  phone: string;
  message: string;
  fromWhereYouKnow: string;
  accept: boolean;
}

interface StateInterface {
  loading: boolean;
  error: boolean;
}

export function OfferForm(props: OfferFormPropsInterface) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<StateInterface>({
    loading: false,
    error: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormInterface>({
    resolver: yupResolver(schema),
  });

  const startLoading = useCallback(() => {
    setState((prev) => ({ ...prev, loading: true }));
  }, []);

  const stopLoading = useCallback(() => {
    setState((prev) => ({ ...prev, loading: false }));

    enqueueSnackbar('Dziękujemy za przesłanie formularza. Skontaktujemy się z Tobą.', {
      variant: 'success',
    });

    reset();
  }, []);

  const showError = useCallback(() => {
    setState((prev) => ({ ...prev, error: true }));

    enqueueSnackbar('Coś poszło nie tak, spróbuj jeszcze raz.', {
      variant: 'error',
    });
  }, []);

  const hideError = useCallback(() => {
    setState((prev) => ({ ...prev, error: false }));
  }, []);

  const onSubmit = useCallback(
    (data: FormInterface) => {
      hideError();

      let id: number;

      if (props.type === 'psychoterapia-indywidualna') {
        id = 510;
      } else if (props.type === 'mdda') {
        id = 508;
      } else {
        id = 501;
      }

      const dataWithType: FormInterface = { ...data };

      formHttp(
        dataWithType,
        {
          error: showError,
          loadstart: startLoading,
          loadend: stopLoading,
        },
        id
      );
    },
    [startLoading, stopLoading, showError, hideError]
  );

  return (
    <>

    <Box sx={{
             marginTop: '20px',
             maxWidth: '100%',
             padding: '0 16px',
             '@media screen and (min-width: 992px)': {
              // marginTop: '0',
            }
            }}>

   
    {(props.type === 'psychoterapia-indywidualna') && (
       
       <iframe 
       style={{
        maxWidth: '100%',
      }}
       src="https://docs.google.com/forms/d/e/1FAIpQLScLhlNGtnnXkWkImQuT5yufANXgVzA3eSMf-1ODPlLk5xYPtg/viewform?embedded=true" 
       width="640" 
       height="382" 
       frameBorder="0" 
       marginHeight={0}
       marginWidth={0} />
    )}

{(props.type === 'ddaplus') && (
     
       <iframe 
       style={{
        maxWidth: '100%',
      }}
       src="https://docs.google.com/forms/d/e/1FAIpQLSf0jUp9JmfcK6ntNdwtmIsEK3e-863jWES_7JaCzfLtbTZ5pA/viewform?embedded=true" 
       width="640" 
       height="382" 
       frameBorder="0" 
       marginHeight={0}
       marginWidth={0} />
      
    )}


{(props.type === 'mdda') && (
      
       <iframe 
       style={{
        maxWidth: '100%',
      }}
       src="https://docs.google.com/forms/d/e/1FAIpQLSdYAxPrJW1HaPJXvu7Ubpghao07AUbvcNCc0VqRhVC5vLOUXQ/viewform?embedded=true" 
       width="640" 
       height="382" 
       frameBorder="0" 
       marginHeight={0}
       marginWidth={0} />
      
    )}
    </Box>
    </ >
    // <Box
    //   sx={{
    //     marginTop: '32px',
    //     backgroundColor: '#F3F3F3',
    //     width: '100%',
    //     maxWidth: '500px',
    //     boxSizing: 'border-box',
    //     padding: '16px',
    //     position: 'relative',
    //     borderRadius: '3px',
    //   }}
    // >
    //   <Typography fontWeight="bold" marginBottom="24px" color="primary" variant="body1">
    //     Zapytaj o terapię
    //   </Typography>
    //   <Box
    //     component="form"
    //     sx={{
    //       display: 'grid',
    //       gap: '16px',
    //       justifyItems: 'start',
    //       position: 'relative',
    //     }}
    //     onSubmit={handleSubmit(onSubmit)}
    //   >
    //     <TextField
    //       fullWidth
    //       variant="outlined"
    //       label="Imię i nazwisko"
    //       {...register('username')}
    //       helperText={errors.username?.message}
    //       error={!!errors.username?.message}
    //     />
    //     <TextField
    //       fullWidth
    //       variant="outlined"
    //       type="number"
    //       label="Wiek"
    //       {...register('age')}
    //       helperText={errors.age?.message}
    //       error={!!errors.age?.message}
    //     />
    //     <TextField
    //       fullWidth
    //       variant="outlined"
    //       label="Email"
    //       {...register('email')}
    //       helperText={errors.email?.message}
    //       error={!!errors.email?.message}
    //     />
    //     <TextField
    //       fullWidth
    //       variant="outlined"
    //       label="Telefon"
    //       type="tel"
    //       {...register('phone')}
    //       helperText={errors.phone?.message}
    //       error={!!errors.phone?.message}
    //     />
    //     <TextField
    //       fullWidth
    //       variant="outlined"
    //       label="Wiadomość"
    //       multiline
    //       rows={8}
    //       {...register('message')}
    //       helperText={errors.message?.message}
    //       error={!!errors.message?.message}
    //     />
    //     <TextField
    //       fullWidth
    //       variant="outlined"
    //       label="Skąd o nas wiesz?"
    //       multiline
    //       rows={4}
    //       {...register('fromWhereYouKnow')}
    //       helperText={errors.fromWhereYouKnow?.message}
    //       error={!!errors.fromWhereYouKnow?.message}
    //     />
    //     <FormControlLabel
    //       control={
    //         <Controller
    //           control={control}
    //           name="accept"
    //           render={({ field: { value, onChange } }) => (
    //             <Checkbox color="primary" checked={!!value} onChange={onChange} />
    //           )}
    //         />
    //       }
    //       label="Akceptuję politykę prywatności i wyrażam zgodę na przetwarzanie danych osobowych w celu realizacji naboru do Programów Psychoterapeutycznych."
    //     />
    //     {!!errors.accept?.message && <FormHelperText error>{errors.accept?.message}</FormHelperText>}
    //     {state.error && <FormHelperText error>Coś poszło nie tak, spróbuj jeszcze raz.</FormHelperText>}
    //     <Button disabled={state.loading} type="submit" variant="contained" color="primary">
    //       Wyślij wiadomość
    //     </Button>
    //   </Box>
    //   {state.loading && (
    //     <Box
    //       sx={{
    //         position: 'absolute',
    //         top: 0,
    //         left: 0,
    //         width: '100%',
    //         height: '100%',
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         backgroundColor: 'rgba(0,0,0,0.1)',
    //         borderRadius: '3px',
    //       }}
    //     >
    //       <CircularProgress size={70} />
    //     </Box>
    //   )}
    // </Box>

  );
}
