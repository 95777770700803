import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { ReactComponent as ArrowRightPrimary } from '../../media/icons/arrow-left.svg';
import { Link } from 'react-router-dom';
import { TileDataInterface } from '../Tiles/Tile';
import { Tiles } from '../Tiles';

interface NewsPropsInterface {
  data: TileDataInterface[];
}

export function News(props: NewsPropsInterface) {
  return (
    <Box
      sx={{
        marginTop: '120px',
        maxWidth: '1144px',
        width: '100%',
        paddingRight: '16px',
        paddingLeft: '16px',
      }}
    >
      <Box
        sx={{
          marginBottom: '32px',
        }}
      >
        <Typography variant="h4">Aktualności</Typography>
      </Box>
      <Tiles data={props.data} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '300px',
        }}
      >
        <Button variant="outlined" color="primary" endIcon={<ArrowRightPrimary />} component={Link} to="/aktualnosci">
          Więcej aktualności
        </Button>
      </Box>
    </Box>
  );
}
