import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Typography, useMediaQuery, useTheme, Box } from '@mui/material';

function getIndexes(array: string[], index: number) {
  const middleIndex = index;

  const leftInnerIndex = middleIndex === 0 ? array.length - 1 : middleIndex - 1;
  const rightInnerIndex = middleIndex === array.length - 1 ? 0 : middleIndex + 1;

  const leftOuterIndex = middleIndex === 1 ? array.length - 1 : leftInnerIndex - 1;
  const rightOuterIndex = middleIndex === array.length - 2 ? 0 : rightInnerIndex + 1;

  return {
    middleIndex,
    leftInnerIndex,
    rightInnerIndex,
    leftOuterIndex,
    rightOuterIndex,
  };
}

const data = [
  `Dzięki udziałowi w Programie „Z dalekiej podróży do własnych kątów” zaczęłam otwierać się na ludzi, nawiązywać przyjaźnie, radzić sobie z problemami w konstruktywny sposób. Moi znajomi z Programu, którzy pamiętają mnie sprzed czterech lat, twierdzą zgodnie, że jestem już nie tą samą osobą. Oni też się zmienili, ale na co dzień nie widzi się tego tak, jak ktoś z boku. Żadna sfera mojego życia nie jest idealna i wolna od problemów, ale już nie boję się ryzykować, zrywać ze starymi schematami. Poza tym dostałam tutaj bardzo dużo niesamowitego ciepła i zrozumienia. Mam nadzieję że jeszcze długo młodzi ludzie będą mogli korzystać z pomocy Ani, Czarka, Asi i innych terapeutów pracujących w OD-DO.`,
  `Zacząłem żyć. Pogodziłem się z krzywdą wyrządzoną przez rodziców. Pogodziłem się ze stratą
dwudziestu paru lat dzieciństwa i dorastania. Przestałem czuć nienawiść. Wziąłem za siebie
odpowiedzialność i zacząłem budować swoje życie od nowa. Zacząłem się cieszyć życiem i z
niego korzystać. Przestałem się użalać nad sobą. Po prostu zacząłem żyć na nowo i dla siebie.
Zostawiłem całe zło za sobą i przestałem czuć się winny za picie ojca i życie matki. Zacząłem
czuć i normalnie funkcjonować. Zacząłem ufać. Zacząłem być sobą.`,
  `Po uczestnictwie w Projekcie „DDA bierze los we własne ręce” mogę stwierdzić, że lepiej
wiem, czym mogę się zawodowo zajmować, utwierdziłam się w tym, co robię dobrze oraz że
warto wierzyć w siebie. Dowiedziałam się też, jak funkcjonuję w grupie i samodzielnie, jakie
mechanizmy mną kierują. Zwiększyła się moja świadomość siebie w czasie pracy z ludźmi i
samodzielnie. Łatwiej mi teraz wysyłać CV i chodzić na rozmowy o pracę. Bardziej wierzę w
siebie i moje umiejętności.`,
  `Podstawowa zmiana jest taka, że wiem, czego się boję, co mnie wkurza, co mnie smuci –
większość moich problemów zyskała jakieś oblicze, dlatego, przynajmniej tak na chwilę
obecną na to patrzę, łatwiej mi będzie zmierzyć się z nimi. Zmaganie z czymś określonym jest
znacznie łatwiejsze niż walka z nieznanym. Po drugie, zrozumiałem, że to ja trzymam się
wielu niefajnych rzeczy we mnie – może kiedyś zbiorę siły, żeby się ich pozbyć. W końcu widzę
także zmiany w relacjach z innymi – jestem bardziej szanowany, poważnie traktowany –
generalnie z osobami, na których mi zależy, poprawiły się moje relacje, w przypadku innych
osób nie czuję już przymusu budowania jakichkolwiek relacji. Chyba zrobiłem się po prostu
doroślejszy.`,
  `Szkolenie „W stronę profesjonalizacji pracy z MDDA” było dla mnie cennym doświadczeniem.
Uświadomiło mi, z iloma problemami borykają się młodzi ludzie z rodzin dysfunkcyjnych.
Pokazało możliwości i kierunki skutecznej pomocy młodym DDA. W bardzo przyjaznej i
bezpiecznej atmosferze, pozwoliło mi również na doświadczenie relacji z moimi bliskimi.
Szkolenie zainspirowało mnie do poszerzania obszarów mojej pracy psychoterapeutycznej i
do własnego rozwoju.`,
];

export function OpinionsSlider() {
  const [main, setMain] = useState<number>(0);
  const isDesktop = useMediaQuery('(min-width: 500px)');
  const theme = useTheme();

  const refs = useRef<HTMLDivElement[]>([]);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  const renderItems = (data: string, index: number) => {
    const setRef = (element: HTMLDivElement) => {
      refs.current[index] = element;
    };

    return (
      <Box
        sx={{
          overflow: 'hidden',
          backgroundColor: theme.palette.background.paper,
          borderRadius: '3px',
          width: '400px',
          height: 'auto',
          boxSizing: 'border-box',
          padding: '8px',
          position: 'absolute',
          transition: 'transform 1500ms ease-in-out, background-color 1500ms ease-in-out, opacity 1500ms ease-in-out',
        }}
        key={index}
        ref={setRef}
      >
        <Typography variant="body1">{data}</Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (!!intervalRef.current) clearTimeout(intervalRef.current);

    setMain((prev) => {
      let main = prev - 1;

      if (main === -1) {
        main = data.length - 1;
      }

      return main;
    });

    intervalRef.current = setInterval(() => {
      setMain((prev) => {
        let main = prev - 1;

        if (main === -1) {
          main = data.length - 1;
        }

        return main;
      });
    }, 15000);
  }, [isDesktop]);

  useLayoutEffect(() => {
    if (!isDesktop) return;

    const { leftOuterIndex, leftInnerIndex, middleIndex, rightInnerIndex, rightOuterIndex } = getIndexes(data, main);
    const leftOuterElement = refs.current[leftOuterIndex];
    const leftInnerElement = refs.current[leftInnerIndex];
    const middleElement = refs.current[middleIndex];
    const rightInnerElement = refs.current[rightInnerIndex];
    const rightOuterElement = refs.current[rightOuterIndex];

    if (!!leftOuterElement) {
      leftOuterElement.style.transform = 'translate(-1000px) scale(0.5)';
      leftOuterElement.style.zIndex = '1';
      leftOuterElement.style.opacity = '0';
    }
    if (!!leftInnerElement) {
      leftInnerElement.style.transform = 'translate(-500px) scale(0.8)';
      leftInnerElement.style.zIndex = '2';
      leftInnerElement.style.opacity = '1';
      leftInnerElement.style.backgroundColor = theme.palette.primary.light;
    }
    if (!!middleElement) {
      middleElement.style.transform = 'translate(0px) scale(1)';
      middleElement.style.zIndex = '3';
      middleElement.style.opacity = '1';
      middleElement.style.backgroundColor = theme.palette.background.paper;
    }
    if (!!rightInnerElement) {
      rightInnerElement.style.transform = 'translate(500px) scale(0.8)';
      rightInnerElement.style.zIndex = '2';
      rightInnerElement.style.opacity = '1';
      rightInnerElement.style.backgroundColor = theme.palette.primary.light;
    }
    if (!!rightOuterElement) {
      rightOuterElement.style.transform = 'translate(1500px) scale(0.5)';
      rightOuterElement.style.zIndex = '1';
      rightOuterElement.style.opacity = '0';
    }

    refs.current.forEach((element, index) => {
      if (
        index === leftOuterIndex ||
        index === leftInnerIndex ||
        index === middleIndex ||
        index === rightInnerIndex ||
        index === rightOuterIndex
      )
        return;

      element.style.transform = 'translate(1500px) scale(0.5)';
      element.style.zIndex = '1';
      element.style.opacity = '0';
    });
  }, [main]);

  if (!isDesktop) return null;

  return (
    <Box
      sx={{
        marginTop: '32px',
        width: '100%',
        maxWidth: '1112px',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          boxSizing: 'border-box',
          padding: '16px',
        }}
      >
        <Typography variant="h3" color="#FFFFFF">
          Rekomendacje
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
          height: '500px',
          width: '100%',
        }}
      >
        {data.map(renderItems)}
      </Box>
    </Box>
  );
}
