import React from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme, CssBaseline, GlobalStyles } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: { main: '#E67300' },
    secondary: { main: '#FFFFFF' },
  },
});

export function ThemeProvider(props: { children: React.ReactNode }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: 'white',
          },
          '#root': {
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
          },
        }}
      />
      {props.children}
    </MuiThemeProvider>
  );
}
