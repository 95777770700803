import React, { useEffect, useRef } from 'react';

export function useResizeObserver<T extends HTMLElement>(callback: (element: T) => void): React.RefObject<T> {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new ResizeObserver(() => {
      if (!ref.current || !callback) return;

      callback(ref.current);
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [callback]);

  return ref;
}
