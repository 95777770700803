import React, { useEffect, useState } from 'react';
import { Typography, Skeleton, useMediaQuery, Box, Button } from '@mui/material';
import { ContactResponseInterface } from '../api/types/ContactTypes';
import { http } from '../api/http';
import { contact } from '../api/queries/contact';
import { Error } from '../components/Error';
import { ContactForm } from '../components/contact/ContactForm';
import PinDropIcon from '@mui/icons-material/PinDrop';

interface StateInterface {
  data: ContactResponseInterface | null;
  loading: boolean;
  error: boolean;
}

export function Contact() {
  const [state, setState] = useState<StateInterface>({
    data: null,
    loading: false,
    error: false,
  });

  const isDesktop = useMediaQuery('(min-width: 500px)');

  useEffect(() => {
    const controller = new AbortController();

    setState((prev) => ({
      ...prev,
      data: null,
      error: false,
      loading: true,
    }));

    http<ContactResponseInterface>(contact, { signal: controller.signal })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        if (controller.signal.aborted) return;

        setState((prev) => ({
          ...prev,
          data: null,
          loading: false,
          error: true,
        }));
      });

    return () => controller.abort();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      {state.error ? (
        <Error />
      ) : (
        <Box
          sx={{
            maxWidth: '1112px',
            width: '100%',
            marginBottom: '56px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '56px',
              marginBottom: '56px',
            }}
          >
            <Typography variant={isDesktop ? 'h2' : 'h3'}>Kontakt</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: '#F3F3F3',
              // display: 'grid',
              // gridTemplateColumns: '1fr',
              // gap: '40px',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              padding: '16px',
              position: 'relative',
              '@media screen and (min-width: 700px)': {
                // gridTemplateColumns: '1fr 1fr',
                // gap: '20px',
                // padding: '20px',
              },
              '@media screen and (min-width: 992px)': {
               gap: '20px',
               padding: '20px',
               display: 'grid',
               gridTemplateColumns: '1fr 1fr',
              },
              '@media screen and (min-width: 1200px)': {
                gap: '40px',
                padding: '40px',
              }
            }}
          >
            <Box 
            
            sx={{
           
             '@media screen and (min-width: 1200px)': {
              minWidth: '350px',}
            
            }}
            
            >
              <Typography fontWeight="bold" marginBottom="16px" color="primary" variant="body1">
                Dane kontaktowe
              </Typography>
              {state.loading && !state.error && (
                <>
                  <Skeleton variant="text" width={180} />
                  <Skeleton variant="text" width={180} />
                  <Skeleton variant="text" width={180} />
                  <Skeleton variant="text" width={180} />
                  <Skeleton variant="text" width={180} />
                  <br />
                  <Skeleton variant="text" width={180} />
                  <Skeleton variant="text" width={180} />
                </>
              )}
              {!!state.data && (
                <>
                  <Typography variant="body1">{state.data.data.contact.data.name}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.address}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.postalCode}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.phone}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.komorka}</Typography>
                  <Typography
                    variant="body1"
                    component="a"
                    href={`mailto:${state.data.data.contact.data.email}`}
                    sx={{
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                    {state.data.data.contact.data.email}
                  </Typography>
                  <Typography variant="body1" marginTop="16px">{state.data.data.contact.data.krs}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.nip}</Typography>
                  <Typography variant="body1">{state.data.data.contact.data.regon}</Typography>
                  <br />
                  <br />
                  <Typography fontWeight="bold" variant="body1">
                    Godziny otwarcia:
                  </Typography>
                  <Typography variant="body1">{state.data.data.contact.data.openingHours}</Typography>
                </>
              )}
              <Typography fontWeight="bold" marginBottom="16px" marginTop="16px" color="primary" variant="body1">
                Wspieraj nas
              </Typography>
              {state.loading && !state.error && <Skeleton variant="text" width={180} />}
              {!!state.data && (
                <Typography variant="body1">nr konta {state.data.data.contact.data.bankAccount}</Typography>
              )}
              <Button
                sx={{ marginTop: '24px' }}
                target="_blank"
                href="https://goo.gl/maps/A6gFacViNp9po5VZ9"
                variant="contained"
                color="primary"
                startIcon={<PinDropIcon />}
              >
                Znajdź nas
              </Button>
            </Box>



            {/* <ContactForm /> */}


            <Box sx={{
             marginTop: '20px',
             '@media screen and (min-width: 992px)': {
              marginTop: '0',}
            }}>
            <iframe style={{
maxWidth: '100%',
width: '100%',
            }}
            src="https://docs.google.com/forms/d/e/1FAIpQLSc4nnOWgG09-hjfE5WhDM_JIKe5ZY6zPARlH7xmup5L2o1mKA/viewform?embedded=true"  
            width="600" 
            height="500" 
            frameBorder="0" 
            marginHeight={0}
            marginWidth={0} />
         </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
