import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Landing } from './views/Landing';
import { App } from './App';
import { About } from './views/About';
import { Psychotherapy } from './views/Psychotherapy';
import { Offer } from './views/Offer';
import { WhoAreAca } from './views/WhoAreAca';
import { WhatIsPsychotherapy } from './views/WhatIsPsychotherapy';
import { Article } from './views/Article';
import { News } from './views/News';
import { Projects } from './views/Projects';
import { Media } from './views/Media';
import { Contact } from './views/Contact';
import { SupportUs } from './views/SupportUs';
import { Privacy } from './views/Privacy';

export function Routing() {
  return (
    <Routes>
      <Route element={<App />}>
        <Route path="" element={<Landing />} />
        <Route path="kim-jestesmy" element={<About />} />
        <Route path="psychoterapia" element={<Psychotherapy />} />
        <Route path="psychoterapia/psychoterapia-indywidualna" element={<Offer type="psychoterapia-indywidualna" />} />
        <Route path="psychoterapia/mdda" element={<Offer type="mdda" />} />
        <Route path="psychoterapia/ddaplus" element={<Offer type="ddaplus" />} />
        <Route path="kim-sa-dda" element={<WhoAreAca />} />
        <Route path="psychoterapia-czym-jest" element={<WhatIsPsychotherapy />} />
        <Route path="artykul/:id" element={<Article />} />
        <Route path="aktualnosci" element={<News />} />
        <Route path="projekty" element={<Projects />} />
        <Route path="media" element={<Media />} />
        <Route path="kontakt" element={<Contact />} />
        <Route path="wspieraj-nas" element={<SupportUs />} />
        <Route path="polityka-prywatnosci" element={<Privacy />} />
        <Route path="*" element={<Navigate to="" />} />
      </Route>
    </Routes>
  );
}
