export const contact = `{
  contact: page(id: "cG9zdDoxMTQ=") {
    data: contact {
      address
      bankAccount
      email
      name
      openingHours
      phone
      postalCode
      nip
      regon
      krs
      komorka
    }
  }
    links: page(id: "cG9zdDoxMTQ=") {
    contact {
      linkFacebook
      linkInstagram
      schoolLink
    }
  }
}`;
